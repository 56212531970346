<template>
  <v-container fluid>
    <v-row v-if="templates !== null">
      <template v-for="template in templates">
        <v-col
          :key="template.template_id"
          lg="4"
          md="4"
          sm="6"
          xs="12"
        >
          <SiteTemplateCard :template="template" />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>

  import SiteTemplateCard from "./SiteTemplateCard"

  export default {
    name: 'SiteTemplateGrid',
    components: {
      SiteTemplateCard
    },
    props: {
      templates: {
        type: Array, 
        default: () => []
      }
    },
    data: () => ({
    }),
  }
</script>

<style>
  .dense-border {
    border: 2px #999 dashed;
  }

  .box {
    display: block;
    width: 100%;
  }

  .dense-border:hover {
    cursor: pointer;
    background: #fff;
  }

</style>
