<template>
  <v-card class="mx-auto">
    <v-img :src="template.thumbnail_url" />
    <v-card-title>{{ template.template_name }}</v-card-title>
    <v-card-actions>
      <v-btn
        @click="buildSite"
        block
        color="primary"
        :disabled="building"
        :loading="building"
      >
        {{ $t('buildSite') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import axios from "axios"

  export default {
    name: 'TemplateCard',
    props: {
      template: {
        type: Object, 
        default: () => {}
      }
    },
    data: () => ({
      building: false
    }),
    methods: {
      manageSite: function(site) {

        const query = '{ ssoSitesLink(site_name: "' + site.site_name + '") }'

        axios({
          url: this.$store.state.graphUrl,
          method: 'post',
          data: {
            query: query
          }
        }).then(() => {
          window.parent.location = 'https://sites.salescloud.is'
        })

      },
      buildSite: function() {

        this.building = true

        axios({
          url: this.$store.state.graphUrl,
          method: 'post',
          data: {
            query: 'mutation { createSite(template: {template_id: ' + this.template.template_id + '}) { site_name } }'
          }
        }).finally(() => {
          this.building = false
          this.$store.dispatch('getSites')
        })
      }
    }
  }
</script>
